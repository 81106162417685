<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Stok Grubu Ekle
          </h6></b-col>
        </b-row>
        <b-form-group label="Grup Adı">
          <b-form-input
            v-model="dataItem.title"
            placeholder="Grup Adı"
          />
        </b-form-group>
        <b-form-group label="Ana Grup">
          <v-select
            id="color"
            v-model="dataItem.id_com_sparepart_stock_groups"
            label="title"
            :reduce="item => item.id"
            :options="mainGroups"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="dataItem.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  computed: {
    saveStatus() {
      return this.$store.getters['sparepartStockGroups/dataSaveStatus']
    },
    dataItem() {
      return this.$store.getters['sparepartStockGroups/dataItem']
    },
    mainGroups() {
      return this.$store.getters['sparepartStockGroups/dataList']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          name: null,
          web: '1',
          salestatus: '1',
          servicestatus: '1',
          ordering: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    this.$store.commit('sparepartStockGroups/RESET_DATA_ITEM')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('sparepartStockGroups/getDataList', {
        select: [
          'com_sparepart_stock_groups.id AS id',
          'com_sparepart_stock_groups.title AS title',
        ],
        where: {
          'com_sparepart_stock_groups.id_com_sparepart_stock_groups': null,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.dataItem.submitStatus = true
      this.$store.dispatch('sparepartStockGroups/saveData', this.dataItem)
    },
  },
}
</script>
